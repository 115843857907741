<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true" size="xl">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <div v-if="haveIncident()">
      <div class="ui-type-heading mb-3">
        S{{ incidenId() }} - {{ incidentFamilyName() }}
      </div>

      <div class="mb-3">
        <div class="ui-type-subheading text-uppercase">Messaggio</div>
        <span v-html="record.incident.msg"></span>
      </div>

      <div class="ui-type-subheading text-uppercase">Articolo</div>

      <CRow class="mb-3">
        <CCol md="3"
          ><strong>{{ incidentDetailVariantName() }}</strong
          ><br />{{ incidentDetailPriceOrig() }}</CCol
        >
        <CCol md="3"
          ><strong>Ordine #{{ incidentDetailOrderId() }}</strong
          ><br />{{ incidentDetailOrderFamilyName() }}</CCol
        >
        <CCol md="3"><div v-html="incidentDetailStatus()"></div></CCol>
      </CRow>

      <div class="ui-type-subheading text-uppercase">Gestione cliente</div>

      <CRow class="mb-3">
        <CCol md="3">
          <CSelect
            :value.sync="form.action"
            :options="actionOptions"
            :disabled="true"
            custom
          >
            <template #label>
              <strong>Azione</strong>
            </template>
          </CSelect>
        </CCol>
        <CCol md="3" v-if="showReplacedWith">
          <CInput v-model="form.replacedWith" :readonly="true">
            <template #label>
              <strong>Sostituito</strong>
            </template>
          </CInput>
        </CCol>
        <CCol md="3" v-if="showNewWeight">
          <CInput
            v-model="form.newWeight"
            type="number"
            min="0"
            step="0.1"
            :append="unit"
            :readonly="true"
          >
            <template #label>
              <strong>Cambio peso</strong>
            </template>
          </CInput>
        </CCol>
        <CCol md="3" v-if="showPriceNew">
          <CInput
            v-model="form.priceNew"
            :append="currencySymbol"
            :disabled="disablePriceNew"
            type="number"
            step="0.01"
            :readonly="true"
          >
            <template #label>
              <strong>Nuovo prezzo cliente</strong>
            </template>
          </CInput>
        </CCol>
      </CRow>

      <div v-if="showPriceDetails">
        <div class="ui-type-subheading text-uppercase">Gestione incassi</div>

        <div class="mb-3">
          <CRow class="align-items-center">
            <CCol md="3">
              <CInput
                v-model="form.priceProducer"
                :append="currencySymbol"
                :disabled="disablePriceProducer"
                type="number"
                step="0.01"
                :readonly="true"
              >
                <template #label>
                  <strong>Produttore</strong>
                </template>
              </CInput>
            </CCol>
            <CCol md="3">
              <CInput
                v-model="form.priceMarket"
                :append="currencySymbol"
                :disabled="disablePriceMarket"
                type="number"
                step="0.01"
                :readonly="true"
              >
                <template #label>
                  <strong>Mercato</strong>
                </template>
              </CInput>
            </CCol>
            <CCol md="3">
              <CInput
                v-model="form.priceIsland"
                :append="currencySymbol"
                :disabled="disablePriceIsland"
                type="number"
                step="0.01"
                :readonly="true"
              >
                <template #label>
                  <strong>Consegna</strong>
                </template>
              </CInput>
            </CCol>
          </CRow>
        </div>
      </div>

      <div class="ui-type-subheading text-uppercase">Dettagli</div>

      <CRow class="mb-3">
        <CCol md="6">
          <CInput v-model="form.note" :readonly="true">
            <template #label>
              <strong>Note</strong>
            </template>
          </CInput>
        </CCol>
      </CRow>
    </div>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close"
        >CHIUDI</CButton
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { INCIDENT_ACTIONS, INCIDENT_ACTIONS_PRICE } from "../../config/global";
import { formatPrice, getOptionLabel } from "../../helpers/common";
import { presentIncidentDetailStatus, round } from "../../helpers/incident";
import { getPriceUnitOptions } from "../../helpers/options";

export default {
  name: "IncidentDetailsModal",

  data() {
    return {
      INCIDENT_ACTIONS_PRICE,
      form: {
        action: INCIDENT_ACTIONS.DISCOUNT,
        newWeight: "",
        replacedWith: "",
        priceNew: "",
        priceProducer: "",
        priceMarket: "",
        priceIsland: "",
        note: "",
      },
      loading: false,
      error: null,
    };
  },

  computed: {
    title() {
      return "Dettagli segnalazione";
    },

    show() {
      return this.$store.state.incidents.view.show;
    },

    record() {
      return this.$store.state.incidents.view.incidentDetail;
    },

    lang() {
      return this.$i18n.locale;
    },

    unit() {
      return getOptionLabel(
        get(this.record, "ppm.lot.productproducer.pw_unit"),
        getPriceUnitOptions()
      );
    },

    actionOptions() {
      const actions = [
        {
          value: INCIDENT_ACTIONS.CANCELLED,
          label: "annullamento",
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS.CANCELLED_FOR,
          label: "annullamento a carico di",
          enabled: true,
        },

        {
          value: INCIDENT_ACTIONS.REPLACEMENT,
          label: "sostituzione",
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS.DISCOUNT,
          label: "solo sconto",
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS.CHANGE_WEIGHT,
          label: "cambio peso",
          enabled: this.isWeightProduct(),
        },
      ];

      return actions.filter((action) => action.enabled);
    },

    actionPriceOptions() {
      const actionsPrice = [
        {
          value: INCIDENT_ACTIONS_PRICE.PROPORTIONALLY,
          label: "Dividi proporzionalmente",
          enabled: this.form.action !== INCIDENT_ACTIONS.CANCELLED_FOR,
        },
        {
          value: INCIDENT_ACTIONS_PRICE.CHARGE_PRODUCER,
          label: "Tutto a carico del produttore",
          enabled: true,
        },

        {
          value: INCIDENT_ACTIONS_PRICE.CHARGE_MARKET,
          label: "Tutto a carico del mercato",
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS_PRICE.CHARGE_ISLAND,
          label: "Tutto a carico dell’isola",
          enabled: true,
        },
      ];

      return actionsPrice.filter((action) => action.enabled);
    },

    showPriceNew() {
      return [
        INCIDENT_ACTIONS.DISCOUNT,
        INCIDENT_ACTIONS.REPLACEMENT,
        INCIDENT_ACTIONS.CHANGE_WEIGHT,
      ].includes(this.form.action);
    },

    disablePriceNew() {
      return [INCIDENT_ACTIONS.CHANGE_WEIGHT].includes(this.form.action);
    },

    disablePriceProducer() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    disablePriceMarket() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    disablePriceIsland() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    showReplacedWith() {
      return [INCIDENT_ACTIONS.REPLACEMENT].includes(this.form.action);
    },

    showNewWeight() {
      return [INCIDENT_ACTIONS.CHANGE_WEIGHT].includes(this.form.action);
    },

    showPriceDetails() {
      return this.form.action !== INCIDENT_ACTIONS.CHANGE_WEIGHT;
    },

    pricesInconsistence() {
      const price = this.getPriceToMatch();
      const { priceProducer, priceMarket, priceIsland } = this.form;
      const partialsSum =
        parseFloat(priceProducer) +
        parseFloat(priceMarket) +
        parseFloat(priceIsland);

      return round(price) !== round(partialsSum);
    },

    disableActionRicButton() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  watch: {
    show(value) {
      // reset on open/close modal
      if (value === true) {
        this.setup();
      } else {
        // this.reset();
      }
    },

    "form.action"(value) {
      const {
        orig_price,
        orig_credit_producer,
        orig_credit_market,
        orig_credit_island,
        credit_producer,
        credit_market,
        credit_island,
      } = this.getPrices();

      if (
        [INCIDENT_ACTIONS.REPLACEMENT, INCIDENT_ACTIONS.DISCOUNT].includes(
          value
        )
      ) {
        this.form.priceNew = orig_price;
        this.form.priceProducer = orig_credit_producer;
        this.form.priceMarket = orig_credit_market;
        this.form.priceIsland = orig_credit_island;
      }

      if ([INCIDENT_ACTIONS.CANCELLED].includes(value)) {
        this.form.priceNew = 0;
        this.form.priceProducer = 0;
        this.form.priceMarket = 0;
        this.form.priceIsland = 0;
      }

      if ([INCIDENT_ACTIONS.CANCELLED_FOR].includes(value)) {
        this.form.priceNew = 0;
        this.form.priceProducer = credit_producer;
        this.form.priceMarket = credit_market;
        this.form.priceIsland = credit_island;
      }

      if ([INCIDENT_ACTIONS.CHANGE_WEIGHT].includes(value)) {
        const pricePerUnit = this.pricePerUnit();
        this.form.priceNew = round(pricePerUnit * this.form.newWeight);
        this.form.priceProducer = orig_credit_producer;
        this.form.priceMarket = orig_credit_market;
        this.form.priceIsland = orig_credit_island;
      }
    },

    "form.newWeight"(value) {
      if (this.form.action === INCIDENT_ACTIONS.CHANGE_WEIGHT) {
        const pricePerUnit = this.pricePerUnit();
        this.form.priceNew = round(pricePerUnit * value);
      }
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.form = {
        action: INCIDENT_ACTIONS.DISCOUNT,
        newWeight: "",
        replacedWith: "",
        priceNew: "",
        priceProducer: "",
        priceMarket: "",
        priceIsland: "",
        note: "",
      };
    },

    setup() {
      this.form = {
        action: get(
          this.record,
          "incident.detail.extra.action",
          INCIDENT_ACTIONS.DISCOUNT
        ),
        newWeight: get(this.record, "qta", 0),
        replacedWith: get(
          this.record,
          "incident.detail.extra.replacedWith",
          ""
        ),
        note: get(this.record, "incident.detail.extra.note", ""),
        priceNew: get(this.record, "price", 0),
        priceProducer: get(this.record, "credit_producer", 0),
        priceMarket: get(this.record, "credit_market", 0),
        priceIsland: get(this.record, "credit_island", 0),
      };
    },

    haveIncident() {
      return get(this.record, "incident", null) !== null;
    },

    close() {
      this.$store.commit("incidents/viewHide");
    },

    incidenId() {
      return get(this.record, "incident.id");
    },

    incidentFamilyName() {
      return get(this.record, "incident.by.family.name");
    },

    incidentDetailOrderId() {
      return get(this.record, "order.id");
    },

    incidentDetailOrderFamilyName() {
      return get(this.record, "order.family.name");
    },

    incidentDetailPriceOrig() {
      return formatPrice(
        get(this.record, "incident.detail.orig_price", 0),
        this.currencySymbol
      ).format();
    },

    incidentDetailVariantName() {
      const productName = get(
        this.record,
        `ppm.lot.productproducer.translations.${this.lang}.name`
      );

      const variantName = get(
        this.record,
        `ppm.lot.translations.${this.lang}.name`
      );

      return `${productName} - ${variantName}`;
    },

    incidentDetailStatus() {
      return presentIncidentDetailStatus(this.record);
    },

    isWeightProduct() {
      return get(this.record, "ppm.lot.productproducer.pw", false) === true;
    },

    getPrices() {
      return {
        orig_price: get(this.record, "incident.detail.orig_price", 0),
        orig_credit_producer: get(
          this.record,
          "incident.detail.orig_credit_producer",
          0
        ),
        orig_credit_market: get(
          this.record,
          "incident.detail.orig_credit_market",
          0
        ),
        orig_credit_island: get(
          this.record,
          "incident.detail.orig_credit_island",
          0
        ),
        credit_producer: get(this.record, "credit_producer"),
        credit_market: get(this.record, "credit_market"),
        credit_island: get(this.record, "credit_island"),
      };
    },

    pricePerUnit() {
      const { price, qta } = this.record;
      return round(price / qta);
    },

    getPriceToMatch(format = false) {
      return format
        ? formatPrice(this.form.priceNew, this.currencySymbol).format()
        : this.form.priceNew;
    },
  },
};
</script>
